import React from 'react';
import Default from '../components/pages/Default';
import Layout from '../components/Layout';

const App = () => (
  <Layout>
    <Default />
  </Layout>
);

export default App;
